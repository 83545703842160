import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="trust-account--request"
export default class extends Controller {
  static targets = ["check", "submit"]

  connect() {
    this.checkTarget.addEventListener("change", () => this.enableSubmit())
  }

  enableSubmit() {
    this.submitTarget.disabled = !this.checkTarget.checked
  }

  disconnect() {
    this.checkTarget.removeEventListener("change", () => this.enableSubmit())
  }
}
