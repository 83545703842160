import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="charge--autopay"
export default class extends Controller {
  static targets = [
    "accNextButton", // step 1
    "billingCyclesNextButton", // step 2
    "accName", "accNumber", "billingCycleDescription", "submit", // step 3 - review
  ]

  connect() {
    let selectedAccountInput = this.element.querySelector('input[name="account"]:checked')

    if (selectedAccountInput) {
      this.pupulateAccountRelevantTargets(selectedAccountInput)
    }

    let selectedBillingCycleInput = this.element.querySelector('input[name="billing_cycle"]:checked')
    if (selectedBillingCycleInput) {
      this.pupulateBillingCycleRelevantTargets(selectedBillingCycleInput)
    }
  }

  enableSubmit(e) {
    this.submitTarget.disabled = !(e.target.checked)
  }

  checkAccount(ev) {
    this.pupulateAccountRelevantTargets(ev.currentTarget)
  }

  checkBillingCycle(ev) {
    this.pupulateBillingCycleRelevantTargets(ev.currentTarget)
  }

  pupulateAccountRelevantTargets(object) {
    this.accNameTarget.innerHTML = object.dataset.name
    this.accNumberTarget.innerHTML = object.dataset.number
    this.accNextButtonTarget.disabled = false
  }

  pupulateBillingCycleRelevantTargets(object) {
    this.billingCycleDescriptionTarget.innerHTML = object.dataset.description
    this.billingCyclesNextButtonTarget.disabled = false
  }
}
