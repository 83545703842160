// app/javascript/controllers/transactions/custom_text_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { transactionId: Number }

  sendWebformLink() {
    const customMessage = document.getElementById("custom_sms_message").value;
    const transactionId = this.transactionIdValue;

    fetch(`/transactions/${transactionId}/trigger_webform`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        'Accept': 'text/vnd.turbo-stream.html'
      },
      body: new URLSearchParams({ message: customMessage })
    })
    .then(response => {
      if (response.ok) {
        return response.text();
      } else {
        throw new Error('Network response was not ok.');
      }
    })
    .then(turboStream => {
      Turbo.clearCache();
      Turbo.renderStreamMessage(turboStream);
    })
    .catch(error => {
      console.error("Error sending webform link:", error);
    });
  }
}
