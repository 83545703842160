import { application } from "./application"

import { Dropdown, Tabs, Toggle, Alert, Modal } from "tailwindcss-stimulus-components"
application.register('dropdown1', Dropdown)
application.register('tabs', Tabs)
application.register('toggle', Toggle)
application.register('alert', Alert)
application.register('modal', Modal)

import Flatpickr from 'stimulus-flatpickr'
application.register('flatpickr', Flatpickr)

import RailsNestedForm from '@stimulus-components/rails-nested-form'
application.register('nested-form', RailsNestedForm)
