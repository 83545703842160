import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static values = {
    url: String
  }

  async run() {
    const response = await get(this.urlValue, { responseKind: "turbo-stream" })

    if (response.ok) {
      // turbo stream response is automatically processed
    } else {
      console.error(response.error)
    }
  }
}
