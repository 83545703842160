// Entry point for the build script in your package.json file
import "@hotwired/turbo-rails"
import { Turbo } from "@hotwired/turbo-rails"
import Rails from "@rails/ujs"
import TurboPower from 'turbo_power'
TurboPower.initialize(Turbo.StreamActions)
import "chartkick/chart.js"

// Make accessible for Electron and Mobile adapters
window.Rails = Rails
window.Turbo = Turbo

// ActionCable Channels
import "./channels"

// Stimulus controllers
import "./controllers"
import "./controllers/vendors"

// misc JS code
import "./src"
require("local-time").start()

// Function to reset Crisp session
function resetCrispSession() {
  $crisp.push(["do", "session:reset"]);
  // Proceed with the sign-out action
  document.querySelector('a[data-turbo-method="DELETE"]').click();
}

// Make the function available globally
window.resetCrispSession = resetCrispSession;
