import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["propertiesContainer", "permissionType"]

  connect() {
    this.permissionTypeChanged()
  }

  permissionTypeChanged() {
    let permissionType = this.permissionTypeTarget.value
    this.propertiesContainerTarget.style.display = permissionType == "full" ? "none" : "block"
  }
}
