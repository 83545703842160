import { ApplicationController, useDebounce } from 'stimulus-use'
import { get } from "@rails/request.js"

// Connects to data-controller="expand"
export default class extends ApplicationController {
  static targets = ["checkbox"]
  static debounces = ['search']
  static values = { url: String }

  connect() {
    useDebounce(this)
  }

  search(event) {
    const queryKey = event.target.name
    
    let searchParams = new URLSearchParams()
    searchParams.append(queryKey, event.target.value)

    get(this.urlValue + `&${searchParams.toString()}`, {
      responseKind: "turbo-stream",
      headers: {
        "Accept": "text/vnd.turbo-stream.html",
        "Content-Type": "text/vnd.turbo-stream.html"
      }
    })
  }
}