import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]
  static values = { lastKey: String }

  connect() {
    let input = this.element // this.inputTarget

    input.addEventListener('keydown', (event) => {
      this.lastKeyValue = event.key;
      this.format()
    });

    input.addEventListener('input', (event) => {
      this.format()
    });

    this.format()
  }

  format() {
    if (this.lastKeyValue == "Backspace" || this.lastKeyValue == "Delete") {
      return;
    }

    let input = this.element // this.inputTarget

    if (input.value.length == 0) {
      return 
    }

    let phoneNumber = input.value.replace(/\D/g,''); // Remove all non-digits
    let len = phoneNumber.length;

    if (len < 3) {
      input.value = `(${phoneNumber}`;
    } else if (len == 3) {
      input.value = `(${phoneNumber}) `;
    } else if (len < 6) {
      input.value = phoneNumber.replace(/(\d{3})(\d{0,2})/, '($1) $2');
    } else if (len == 6) {
      input.value = phoneNumber.replace(/(\d{3})(\d{3})/, '($1) $2-');
    } else if (len <= 10) {
      input.value = phoneNumber.replace(/(\d{3})(\d{3})(\d{0,4})/, '($1) $2-$3');
    } else {
      input.value = phoneNumber.slice(0, 10).replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'); // Limit number to 10 digits
    }
  }
}
