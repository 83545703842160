import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkbox-select-all"
export default class extends Controller {
  static targets = ["parent", "child"]

  connect() {
    if(this.hasParentTarget) {
      let parentChecked = this.childTargets.every(x => x.checked)
      this.parentTarget.checked = parentChecked
    }
  }

  notDisabledChilTargets() {
    return this.childTargets.filter(x => x.disabled == false)
  }

  toggleChildren() {
    if (this.parentTarget.checked) {
      this.notDisabledChilTargets().map(x => x.checked = true)
    } else {
      this.notDisabledChilTargets().map(x => x.checked = false)
    }
    this.toggle_sync_button()
  }

  toggleParent() {
    if(!this.hasParentTarget) {
      return false
    }

    if (this.childTargets.map(x => x.checked).includes(false)) {
      this.parentTarget.checked = false

    } else {
      this.parentTarget.checked = true
    }
    this.toggle_sync_button()
  }

  toggle_sync_button() {
    if (document.querySelector("#sync-txn-btn") === null) {
      return;
    }
    if (this.childTargets.map(x => x.checked).filter(x => x === true).length > 0){
      document.querySelector("#sync-txn-btn").disabled = false
      document.querySelector("#sync-txn-btn").classList.remove("btn-outline")
    } else {
      document.querySelector("#sync-txn-btn").disabled = true
      document.querySelector("#sync-txn-btn").classList.add("btn-outline")
    }
  }
}
