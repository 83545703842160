import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    ransackSelector: String
  }

  connect() {
    this.ransackSelector = this.element.dataset.ransackSelector
    this.ransackSelector ||= this.ransackSelectorValue
  }

  clickCustomAction(e) {
    const cssPath = `input[name='q[${this.ransackSelector}][]']`
    this.element.querySelectorAll(cssPath).forEach((obj) => obj.checked = false)

    const cssPathForCustomActionInputs = `input:not(input[name='q[${this.ransackSelector}][]'])`
    this.element.querySelectorAll(cssPathForCustomActionInputs).forEach((obj) => { 
      if(obj != e.currentTarget) {
        obj.checked = false
      }
    })
  }

  clickProperty(e) {
    const cssPath = `input:not(input[name='q[${this.ransackSelector}][]'])`
    this.element.querySelectorAll(cssPath).forEach((obj) => obj.checked = false)
  }
}
