import { Controller } from "@hotwired/stimulus";
import lightGallery from "lightgallery";
import lgZoom from "lightgallery/plugins/zoom";
import lgRotate from "lightgallery/plugins/rotate";
import { get } from "@rails/request.js";

// Connects to data-controller="gallery"
export default class extends Controller {
  static values = { url: String, licenseKey: String, index: Number };

  async open(ev) {
    const receipts = await this.getReceiptData();
    this.showGallery(receipts);
  }

  // private

  async getReceiptData(q, callback) {
    const response = await get(this.urlValue, {
      responseKind: "json",
    });

    if (response.ok) {
      const receipts = await response.json;
      return receipts;
    } else {
      console.error(response);
    }
  }

  showGallery(data) {
    let opts = {
      dynamic: true,
      plugins: [lgZoom, lgRotate],
      dynamicEl: data,
      flipHorizontal: false,
      flipVertical: false,
    };

    if (this.licenseKeyValue) {
      opts.licenseKey = this.licenseKeyValue;
    }

    if (this.indexValue) {
      opts.index = this.indexValue;
    }

    this.gallery = lightGallery(this.element, opts);
    this.gallery.openGallery();
  }
}
