import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["cardNo", "cvv2"]
  static values = { id: String, type: String }

  async showCardNo(event) {
    event.preventDefault()
    const data = await this.fetchCardDetails("pan")
    if (data) this.cardNoTarget.textContent = data
  }

  async showCVV2(event) {
    event.preventDefault()
    const data = await this.fetchCardDetails("cvv2")
    if (data) this.cvv2Target.textContent = data
  }

  async fetchCardDetails(key) {
    try {
      let url = `cards/${this.idValue}/vgs?` + new URLSearchParams({ key })

      if (this.typeValue == 'charge') url = `/charge_cards/${this.idValue}/vgs?` + new URLSearchParams({ key })

      const response = await fetch(url,
        {
          mode: "cors",
          method: "GET",
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json"
          }
        })

      if (response.redirected) {
        window.location.assign(response.url);
      }
      else {
        const body = await response.json()
        return body.data
      }
    } catch (error) {
      return false
    }
  }
}
