// Reconnect ActionCable after switching accounts

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = []

  static values = {
    approveUrl: String,
    denyUrl: String
  }

  approve(e) {
    e.preventDefault()
    this.element.action = this.approveUrlValue
    this.element.requestSubmit()
  }

  deny(e) {
    e.preventDefault()
    this.element.action = this.denyUrlValue
    this.element.requestSubmit()
  }
}
