import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select"]

  connect() {
    this.turboFrame = this.element
  }

  switch() {
    this.turboFrame.src = this.currentOption.dataset["url"]
    this.turboFrame.reload()
  }

  get currentOption() {
    return this.selectTarget.options[this.selectTarget.selectedIndex]
  }
}
