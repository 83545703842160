import { Controller } from "@hotwired/stimulus"
import { useWindowFocus } from "stimulus-use"
import TomSelect from "tom-select"

// Connects to data-controller="ts--select"
export default class extends Controller {
  static values = {
    options: Object,
    autoOpen: Boolean,
    dropdownInput: Boolean,
    modal: Boolean,
  }

  connect() {
    window.addEventListener("turbo:morph", this.reconnect.bind(this))
    this.initTomSelect()
    this.autoOpen()
  }

  reconnect() {
    if (this.select) {
      let currentValue = this.select.getValue()
      this.select.destroy()
      this.initTomSelect()
      this.select.setValue(currentValue)
      this.autoOpen()
    }
  }

  disconnect() {
    if (this.select) {
      this.select.destroy()
    }
  }

  initTomSelect() {
    if (this.element) {
      let placeholder = this.optionsValue.placeholder
      let onChangeCallback = new Function(this.optionsValue.onChange)

      let plugins = ["clear_button"]

      if (this.optionsValue.allowRemove) {
        plugins.push("remove_button")
      }

      let closeAfterSelect;

      if ('closeAfterSelect' in this.optionsValue) {
        closeAfterSelect = JSON.parse(this.optionsValue.closeAfterSelect)
      } else {
        closeAfterSelect = true;
      }

      var options = {
        plugins: plugins,
        valueField: 'id',
        closeAfterSelect: closeAfterSelect,
        labelField: 'name',
        searchField: 'name',
        maxItems: this.maxItems,
        maxOptions: 1000,
        selectOnTab: true,
        placeholder: placeholder,
        hidePlaceholder: true,
        create: false,
        openOnFocus: true,
        highlight: true,
        onChange: onChangeCallback,
        onItemAdd: this.onItemAdd.bind(this),
        onItemRemove: this.onItemRemove.bind(this),
        onBlur: this.onBlur.bind(this),
        sortField: {
          field: "name",
          direction: "asc"
        },
      }

      if (this.hasOptionsValue && ("items" in this.optionsValue)) {
        options["items"] = this.optionsValue.items
      }

      if (this.dropdownInputValue) {
        options.plugins.push('dropdown_input')
      }

      if (this.modalValue) {
        options.dropdownParent = "body"
      }

      this.select = new TomSelect(this.element, options)
    }
  }

  autoOpen() {
    if (this.hasAutoOpenValue) {
      this.select.open()
    }
  }

  onItemAdd(value, $item) {
    this.dispatch("itemAdd", { detail: { value: value } })
  }

  onItemRemove(value) {
    this.dispatch("itemRemove", { detail: { value: value } })
  }

  onBlur(value) {
    this.dispatch("blur", { detail: { value: value } })
  }

  get maxItems() {
    if (this.hasOptionsValue && this.optionsValue['maxItems']) {
      return this.optionsValue['maxItems'];
    } else {
      return this.element.multiple ? 100 : 1;
    }
  }
}
