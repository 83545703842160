import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

// Connects to data-controller="dropdown"
export default class extends Controller {
  static targets = ["rangeContainer", "fromInput", "toInput"]

  connect() {
    if(this.hasRangeContainerTarget) {
      this.toggle()
    }
  }

  toggle() {
    let selectedRadio = this.element.querySelector("input[type='radio']:checked")
    if(selectedRadio && selectedRadio.value == "custom") {
      this.rangeContainerTarget.classList.remove("hidden")
    } else {
      this.rangeContainerTarget.classList.add("hidden")
      this.fromInputTarget.value = null
      this.toInputTarget.value = null
    }
  }
}
