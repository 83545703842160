import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["selectAll", "checkbox"]

  connect() {
    this.updateSelectAllCheckbox()
  }

  toggleSelectAll(event) {
    const isChecked = event.target.checked
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = isChecked
    })
  }

  toggleIndividualCheckbox() {
    const allChecked = this.checkboxTargets.every((checkbox) => checkbox.checked)
    const anyChecked = this.checkboxTargets.some((checkbox) => checkbox.checked)

    this.selectAllTarget.checked = allChecked
    this.selectAllTarget.indeterminate = anyChecked && !allChecked
  }

  updateSelectAllCheckbox() {
    const allChecked = this.checkboxTargets.every((checkbox) => checkbox.checked)
    this.selectAllTarget.checked = allChecked
  }
}
