import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="redeem"
export default class extends Controller {
  static targets = ["points", "credit"]

  connect() {
    this.pointsTarget.addEventListener('input', (e) => {
      this.creditTarget.value = parseInt(this.pointsTarget.value) / 100
    })

    this.creditTarget.addEventListener('input', (e) => {
      this.pointsTarget.value = parseInt(this.creditTarget.value) * 100
    })
  }

}