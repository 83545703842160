import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input", "item" ]

  filter() {
    const searchText = this.inputTarget.value.toLowerCase()

    this.itemTargets.forEach(element => {
      const itemText = element.textContent.toLowerCase()

      if (itemText.includes(searchText)) {
        this.show(element);
      } else {
        this.hide(element);
      }
    });
  }

  show(el) {
    el.classList.remove("hidden");
  }

  hide(el) {
    el.classList.add("hidden");
  }
}
