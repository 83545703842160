import { Controller } from "@hotwired/stimulus" 
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["propertyDropdown", "workOrderContainer"]

  connect() {
    if(this.propertyDropdownTarget.value) {
      this.workOrderContainerTarget.style.display = "block"
    }
  }

  propertyChanged(event) {
    this.workOrderContainerTarget.style.display = "block"
    get(`/work_orders/dropdown.turbostream?property_id=${event.currentTarget.value}`, { responseKind: "turbo-stream" }) //contentType: "application/json"
  }
}
