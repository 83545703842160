import { Controller } from "@hotwired/stimulus"
import { post, destroy } from '@rails/request.js'

// Connects to data-controller="bills--assignment"
export default class extends Controller {
  static targets = ["loading", "success"]
  static values = {createUrl: String}

  onItemAdd(ev) {
    const userId = ev.detail.value;
    this.addAssignee(userId);
  }

  onItemRemove(ev) {
    const userId = ev.detail.value;
    this.removeAssignee(userId);
  }

  async addAssignee(userId) {
    const payload = {
      assignee: {user_id: userId}
    }

    this.animateLoading();
    const response = await post(this.createUrlValue, { body: payload, contentType: "application/json" })

    if (response.ok) {
      this.animateComplete();
    } else {
      console.error(await response.text)
    }
  }

  async removeAssignee(userId) {
    this.animateLoading();
    const response = await destroy(`${this.createUrlValue}/${userId}`, { contentType: "application/json" })

    if (response.ok) {
      this.animateComplete();
    } else {
      console.error(await response.text)
    }
  }

  animateLoading(){
    this.show(this.loadingTarget);
    this.hide(this.successTarget);
  }

  animateComplete(){
    this.hide(this.loadingTarget);
    this.show(this.successTarget);
  }

  hide(el){
    el.classList.add("hidden")
  }

  show(el){
    el.classList.remove("hidden")
  }
}
