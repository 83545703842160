import { Controller } from '@hotwired/stimulus'

export default class CustomTabsController extends Controller {
  static classes = ["activeTab", "inactiveTab"]
  static targets = ["tab", "panel", "select"]
  static values = {
    index: Number,
    updateAnchor: Boolean,
    scrollToAnchor: Boolean,
    scrollActiveTabIntoView: Boolean
  }

  initialize() {
    if (this.anchor) this.indexValue = this.tabTargets.findIndex(tab => tab.id === this.anchor)
  }

  connect() {
    console.log("Connected to CustomTabsController")
    this.updateTabFromHash();
    document.addEventListener('turbolinks:load', () => this.updateTabFromHash());
    // Check if there's an anchor in the URL when the controller connects
    const anchor = window.location.hash.substring(1); // Get the anchor without the '#' character
    let activeTabIndex = 0; // Default to the first tab

    if (anchor) {
      const activeTab = this.tabTargets.find(tab => tab.dataset.id === anchor);
      activeTabIndex = this.tabTargets.indexOf(activeTab);
    }

    this.indexValue = activeTabIndex >= 0 ? activeTabIndex : 0;
    this.showTab();
  }

  updateTabFromHash() {
    const hash = window.location.hash.slice(1); // remove the '#' from the hash
    if (hash) {
      const targetTab = this.tabTargets.find(tab => tab.dataset.id === hash);
      if (targetTab) {
        this.indexValue = this.tabTargets.indexOf(targetTab);
        this.showTab();
      }
    }
  }

  change(event) {
    let newIndex;
    switch (event.currentTarget.tagName) {
      case "SELECT":
        newIndex = event.currentTarget.selectedIndex;
        break;
      case "BUTTON":
        newIndex = this.tabTargets.indexOf(event.currentTarget);
        break;
      default:
        newIndex = this.tabTargets.indexOf(event.currentTarget);
    }
    this.indexValue = newIndex;
    this.showTab();  // Ensure showTab is called to reflect the change.
  }


  nextTab() {
    this.indexValue = Math.min(this.indexValue + 1, this.tabsCount - 1)
  }

  previousTab() {
    this.indexValue = Math.max(this.indexValue - 1, 0)
  }

  firstTab() {
    this.indexValue = 0
  }

  lastTab() {
    this.indexValue = this.tabsCount - 1
  }

  indexValueChanged() {
    this.showTab()
    if (this.updateAnchorValue) {
      const newTabId = this.tabTargets[this.indexValue].id
      const currentUrl = window.location.href
      const newUrl = currentUrl.split('#')[0] + '#' + newTabId
      history.replaceState({}, document.title, newUrl)
      if (this.scrollToAnchorValue) location.hash = newTabId
    }
  }

  showTab() {
    this.panelTargets.forEach((panel, index) => {
      const tab = this.tabTargets[index]
      const isActive = index === this.indexValue
      panel.classList.toggle('hidden', !isActive)
      tab.ariaSelected = isActive ? 'true' : null
      tab.dataset.active = isActive
      tab.classList.toggle(...this.activeTabClasses, isActive)
      tab.classList.toggle(...this.inactiveTabClasses, !isActive)
    })
    if (this.hasSelectTarget) {
      this.selectTarget.selectedIndex = this.indexValue
    }
    if (this.scrollActiveTabIntoViewValue) this.scrollToActiveTab()
  }

  scrollToActiveTab() {
    const activeTab = this.element.querySelector('[aria-selected="true"]')
    if (activeTab) activeTab.scrollIntoView({ inline: 'center' })
  }

  get tabsCount() {
    return this.tabTargets.length
  }

  get anchor() {
    return (document.URL.split('#').length > 1) ? document.URL.split('#')[1] : null;
  }

  disconnect() {
    document.removeEventListener('turbolinks:load', () => this.updateTabFromHash());
  }
}
