// Reconnect ActionCable after switching accounts

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  clear() {
    this.element.querySelectorAll('input').forEach((input) => {
      if (["text", "date", "time", "number"].includes(input.type)) {
        input.value = ""
      } else if (input.type === "checkbox") {
        input.checked = false
      } else if (input.type === "radio") {
        this.clearRadio(input)
      }
    })
  }

  clearRadio(input) {
    if (input.value === null || input.value === "") {
      input.checked = true
    } else {
      input.checked = false
    }
  }
}
