import { Controller } from "@hotwired/stimulus" 
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = [ 
    "itemDropDown", "ownerChargeContainer", "accountingItemContainer", "ownerChargeCheckbox", 
    "markupAmount", "markupPercentage", "markupCap", "amountWithMarkup", "amount", "property", "description", "addAttachmentLinks",
    "ownerChargeCheckboxContainer", "markupContainer",
    "isOwnerCharge", "hours", "vendorHourlyRate", "billVendorId", "laborContainer", "markupableAmount", "markupableAmountContainer"
  ]

  connect() {
    this.refreshAmount()
    this.handleOwnerChargeChange()
    this.handleVendorChange()
    this.propertyChanged()

    // Do not submit form when enter is pressed
    this.element.querySelectorAll("input").forEach((input) => {
      input.addEventListener('keydown', function(event) {
        if (event.key === 'Enter') {
          event.preventDefault();
          return false;
        }
      });
    })

    this.itemToggleHandler()
  }

  handleChange(event) {
    this.refreshAmount()
  }

  handleVendorChange(event) {
    if (!this.hasLaborContainerTarget) {
      return false
    }

    if (this.hasBillVendorIdTarget && this.billVendorIdTarget.value) {

      let hourlyRate = null 

      let data = this.datasetForSelectedDropdownItem(this.billVendorIdTarget)
      if(data.vendorType == "property_manager") {
        let propertyData = this.datasetForSelectedDropdownItem(this.propertyTarget)
        let propertyManagerHourlyRate = propertyData.propertyManagerHourlyRate
        hourlyRate = propertyManagerHourlyRate
      } else {
        hourlyRate = data.hourlyRate
      }

      if(hourlyRate) {
        this.vendorHourlyRateTarget.value = hourlyRate

        if (!this.amountTarget.value) {
          this.recalculateAmount()
        }

        this.laborContainerTarget.style.display = "block"
      } else { 
        this.laborContainerTarget.style.display = "none"
      }
    } else {
      this.laborContainerTarget.style.display = "none"
    }
  }

  handleManualAmountChange(event) {
    this.vendorHourlyRateTarget.value = ""
    this.hoursTarget.value = ""

    this.handleChange()
  }

  recalculateAmount() {
    let hourlyRate = parseFloat(this.vendorHourlyRateTarget.value || 0)
    let hours = parseFloat(this.hoursTarget.value || 0) 

    this.amountTarget.value = this.roundToX(hourlyRate * hours, 2)

    this.refreshAmount()
  }

  itemToggleHandler(event) {
    this.handleChange()

    if (!this.hasAddAttachmentLinksTarget) {
      return
    }

    if(this.hasSomeItemsSelected()) {
      this.addAttachmentLinksTarget.classList.remove("hidden")
    } else {
      this.addAttachmentLinksTarget.classList.add("hidden")
    }
  }

  handleInventoryItemChange(event) {
    let data = this.datasetForSelectedDropdownItem(event.currentTarget)
    let parent = event.currentTarget.closest(".nested-form-wrapper")
    let countRemovedItemsTarget = parent.querySelector(".input-count-removed-items")
    let unitPriceTarget = parent.querySelector(".input-unit-price")
    let totalAmountTarget = parent.querySelector(".input-total-amount")

    if (data.unitPrice) {
      let unitPrice = parseFloat(data.unitPrice) 

      if (!countRemovedItemsTarget.value) {
        countRemovedItemsTarget.value = 1
      }
      let countRemovedItems = parseFloat(countRemovedItemsTarget.value)
      
      unitPriceTarget.value = unitPrice

      totalAmountTarget.value = this.roundToX(countRemovedItems * unitPrice, 2)
    } else {
      countRemovedItemsTarget.value = ""
      unitPriceTarget.value = ""
      totalAmountTarget.value = ""
    }

    this.refreshAmount()
  }

  recalculateInventoryTotalAmount(event) {
    let parent = event.currentTarget.closest(".nested-form-wrapper")

    let unitPrice = parent.querySelector(".input-unit-price").value || 0
    let countRemovedItems = (parent.querySelector(".input-count-removed-items").value || 0)

    parent.querySelector(".input-total-amount").value = this.roundToX(unitPrice * countRemovedItems, 2)

    this.refreshAmount()
  }

  handleOwnerChargeChange() {
    let isOwnerCharge = false 

    if(this.isOwnerChargeTarget.getAttribute("type") == "hidden") { // streamline
      isOwnerCharge = true
    } else { // optional for Track
      isOwnerCharge = this.isOwnerChargeTarget.checked
    }
    
    this.ownerChargeContainerTarget.style.display =  isOwnerCharge ? "block" : "none"
  }


  roundToX(num, X) {    
    return +(Math.round(num + "e+"+X)  + "e-"+X)
  }

  datasetForSelectedDropdownItem(dropdown) {
    let children = dropdown.querySelectorAll("option") // .children will not work for nested select
    for(var i = 0; i < children.length; i++) {
      let item = children[i]
      if (item.value == dropdown.value) {
        return item.dataset
      }
    }a
  }

  getItem() {
    if(!this.hasItemDropDownTarget) {
      return null
    }
    let item_id = this.itemDropDownTarget.value
    let items = JSON.parse(this.itemDropDownTarget.dataset.items)
    let item = null

    if (item_id) {
      return items[item_id]
    }
  }

  manualAmountWithMarkupChange() {
    this.markupPercentageTarget.value = "" 
    let amountWithMarkup = parseFloat(this.amountWithMarkupTarget.value || 0)

    this.markupAmountTarget.value = amountWithMarkup - parseFloat(this.amountTarget.value)
  }

  propertyChanged(e) {
    let data = this.datasetForSelectedDropdownItem(this.propertyTarget)

    if(data.integrationKey == "streamline") {
      this.handleOwnerChargeChange()
    }

    let markupPercentage = data.markupPercentage; 
    let markupCap = data.markupCap; 
    
    if (markupPercentage) {
      this.markupPercentageTarget.value = markupPercentage;
    }
  
    if (markupCap) {
      this.markupCapTarget.value = markupCap;
    }
    
    if(markupPercentage || markupCap) {
      // this.isOwnerChargeTarget.checked = true // problem: when we don't want owner charge - this should not be auto-selected
      this.handleOwnerChargeChange()
      this.refreshAmount()
    }

    if(this.hasBillVendorIdTarget) {
      this.handleVendorChange()
    }
  }
  
  insertPublicReceiptLinks(e) {
    if(e) {
      e.preventDefault();
    }

    let itemsText = this.generatedAttachmentLinksText()

    if (itemsText) {
      let text = this.descriptionTarget.value

      let tmp = text.split(this.descriptionLineItemsTitle())
      console.log(tmp)
      if (tmp.length > 0) {
        text = tmp[0].replace(/\n+$/, '') + itemsText
      } else {
        text += itemsText
      }

      this.descriptionTarget.value = text 
    }
  }

  hasSomeItemsSelected() {
    if(this.items().length > 0) { // simplification
      return true
    }
  }

  descriptionLineItemsTitle() {
    return "---- Items & Receipts ----"
  }

  generatedAttachmentLinksText() {
    let text = `\n\n${this.descriptionLineItemsTitle()}\n`

    let prevUrl = null 
    this.items().forEach(item => {
      let url = item.dataset.publicAttachmentUrl
     
      if (url && url != prevUrl) {
        text += `Download receipt: ${url}\n`
      }
      prevUrl = url

      let description = item.parentElement.querySelector("input[data-destroyable-checkbox-target=descriptionInput]").value

      text += `${description} - $${item.dataset.amount}\n`
    })

    return text
  }

  items() {
    const checkboxes = this.element.querySelectorAll("input.line-item[type='checkbox']:checked");
    return checkboxes
  }

  billableAmount() {
    let amount = 0
    if(this.hasAmountTarget) {
      amount = parseFloat(this.amountTarget.value || 0)
    }
    return amount
  }

  sumAmountOfWorkOrderItems() {
    return Array.from(this.items()).reduce((accumulator, item) => accumulator + parseFloat(item.dataset.amount || 0), 0);
  }

  sumAmountOfInventoryChanges() {
    let elements = Array.from(this.element.querySelectorAll(".work_order_inventory_changes input.input-total-amount"))
    return elements.reduce((accumulator, element) => accumulator + parseFloat(element.value || 0), 0)
  }
  
  refreshAmount() {
    let item = this.getItem()
    
    let billableAmount = this.billableAmount()

    let markupableAmount = billableAmount 
      + this.sumAmountOfWorkOrderItems()
      + this.sumAmountOfInventoryChanges()

    this.markupableAmountTarget.value =  this.roundToX(markupableAmount,2)

    if(markupableAmount < 0) {
      this.markupPercentageTarget.value = 0
      this.markupContainerTarget.style.display = "none"
    }

    if (billableAmount != markupableAmount) {
      this.markupableAmountContainerTarget.display = "block"
    } else {
      this.markupableAmountContainerTarget.display = "none"
    }

    this.refreshMarkupAmounts(markupableAmount) 
  }

  refreshMarkupAmounts(markupableAmount) {
    let markup = parseFloat(this.markupPercentageTarget.value || 0)/100.0

    let markupAmount = parseFloat(this.roundToX(markupableAmount * markup, 2))
    let markupCap =  parseFloat(this.markupCapTarget.value)

    if (markupCap && markupAmount > markupCap) {
      markupAmount = markupCap
    }

    this.markupAmountTarget.value = markupAmount

    let amountWithmarkup = markupableAmount + markupAmount
    this.amountWithMarkupTarget.value = this.roundToX(amountWithmarkup, 2)  
  }
}
