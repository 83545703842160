import { Controller } from "@hotwired/stimulus"

// Auto submits the form containing the tom-select control when:
// - the user selects one option
// - the user clicks outside the control

// Example of use:
// <%= f.select(
//   :property_id,
//   options_for_select(@properties_for_select, @selected_property_id),
//   {include_blank: true},
//   {
//     data: {
//       controller: "ts--select ts--submit",
//       action: "ts--select#save ts--select:blur->ts--select#close"
//     }
//   }
// ) %>

// Connects to data-controller="ts--submit"
export default class extends Controller {
  save(ev) {
    this.isSaving = true
    ev.target.form.requestSubmit()
  }

  close(ev) {
    // the onBlur event on the tomSelect control is invoked
    // a) when the user clicks outside the select
    // b) when the control is destroyed (because the frame is being replaced with new content after the save)
    // in the second case, the event should be ignored
    if(!this.isSaving) {
      ev.target.form.requestSubmit()
    }
  }
}
