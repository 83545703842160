import { Controller } from "@hotwired/stimulus"
import IMask from 'imask';

// Connects to data-controller="mask-currency"
export default class extends Controller {
  connect() {

    var numberMask = IMask(this.element, {
      mask: Number,  // enable number mask

      scale: 2,
      signed: false,
      thousandsSeparator: ',',
      padFractionalZeros: true,
      normalizeZeros: true,
      radix: '.',
      mapToRadix: [ '.' ],
      min: 0

    });
  }
}
