import { Controller } from "@hotwired/stimulus"
import TomSelect      from "tom-select"

// Connects to data-controller="ts--colors"
export default class extends Controller {
  static values = {
    options: Object
  }

  connect() {
    this.initTomSelect()
  }

  disconnect() {
    if (this.select) {
      this.select.destroy()
    }
  }

  initTomSelect() {
    if (this.element) {
      let items = this.optionsValue.items
      let placeholder = this.optionsValue.placeholder
      let onChangeCallback = new Function(this.optionsValue.onChange)

      this.select = new TomSelect(this.element, {
        items: items,
        valueField: 'id',
        labelField: 'name',
        searchField: 'name',
        maxItems: 1,
        maxOptions: 300,
        selectOnTab: true,
        placeholder: placeholder,
        closeAfterSelect: true,
        hidePlaceholder: true,
        create: false,
        openOnFocus: true,
        highlight: true,
        sortField: {
          field: "name",
          direction: "asc"
        },
        controlInput: null,
        render:{
          option: function(data, escape) {
            return `<div class="mt-1 prop-color-${data.id}">` + escape(data.name) + '</div>';
          },
          item: function(data, escape) {
            return `<div class="prop-color-${data.id}">` + escape(data.name) + '</div>';
          }
        }
      })
    }
  }

}
