import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    wrapperSelector: String
  }

  static targets = ["number"]

  add(e) {
    if (this.numberTargets.length > 0) {
      this._fixNumbers()
    }
  }

  remove(e) {
    let wrapperClass = this.wrapperSelectorValue
    let wrapper = e.target.closest(wrapperClass)
    let newRecord = JSON.parse(wrapper.dataset.newRecord)
    let hasSibling = wrapper.parentElement.querySelectorAll(wrapperClass).length > 2

    if (!hasSibling) {
      return
    } else if (newRecord) {
      wrapper.remove()
    } else {
      wrapper.classList.add("hidden")
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.querySelector("input[name*='_destroy']").checked = true
    }

    if (this.numberTargets.length > 0) {
      this._fixNumbers()
    }
  }

  _fixNumbers() {
    let wrapperClass = this.wrapperSelectorValue;

    this.element.querySelectorAll(wrapperClass).forEach((element, index) => {
      let number = this.numberTargets.find((target) => target.closest(wrapperClass) == element)

      if (number) {
        number.innerHTML = index + 1
      }

    })
  }
}
