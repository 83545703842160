import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["selectField", "destroyField", "editLink", "descriptionContainer", "descriptionInput"]

  connect() {
    this.onChange()
    this.updateDescriptionContainer()
  }

  onChange() {
    this.destroyFieldTarget.value = this.selectFieldTarget.checked ? "" : "1"
  }

  editClickHandler(e) {
    e.stopPropagation();
    let description = prompt("Description", this.descriptionInputTarget.value)
    
    if (description != null) {
      this.descriptionInputTarget.value = description
      this.updateDescriptionContainer()
    }
  }

  updateDescriptionContainer() {
    this.descriptionContainerTarget.innerHTML = this.descriptionInputTarget.value || "<i>No description</i>"
  }
}
