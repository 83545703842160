import { Controller } from "@hotwired/stimulus"
import { useMutation } from "stimulus-use";

export default class extends Controller {
  static targets = ["checkbox", "column", "toggleAllCheckbox", "table"]

  static values = {
    key: String,
    columns: Array
  }

  connect() {
    useMutation(this, { element: this.tableTarget, childList: true })

    if (this.storage === null) {
      localStorage.setItem(this.keyValue, JSON.stringify([]));
    }

    this.resolveCheckboxes()
    this.resolveColumnVisibility()
  }

  toggle(event) {
    const column = event.target.dataset["column"];

    if (event.target.checked) {
      this.showColumn(column);
    } else {
      this.hideColumn(column);
    }
  }

  toggleAll(event) {
    if (event.target.checked) {
      this.columnsValue.forEach((column) => {
        this.showColumn(column);
      });
    } else {
      this.columnsValue.forEach((column) => {
        this.hideColumn(column);
      });
    }

    this.resolveCheckboxes()
  }

  hideColumn(column) {
    let hiddenColumns = JSON.parse(this.storage);

    hiddenColumns.push(column);
    localStorage.setItem(this.keyValue, JSON.stringify(hiddenColumns));

    this.resolveColumnVisibility();
  }

  showColumn(column) {
    const hiddenColumns = JSON.parse(this.storage);

    hiddenColumns.splice(hiddenColumns.indexOf(column), 1);
    localStorage.setItem(this.keyValue, JSON.stringify(hiddenColumns));

    this.resolveColumnVisibility();
  }

  resolveCheckboxes() {
    const hiddenColumns = JSON.parse(this.storage);

    this.checkboxTargets.forEach((checkbox) => {
      if (hiddenColumns.includes(checkbox.dataset.column)) {
        checkbox.checked = false;
      } else {
        checkbox.checked = true;
      }
    });
  }

  resolveToggleAllCheckboxStatus() {
    if (JSON.parse(this.storage).length === 0) {
      this.toggleAllCheckboxTarget.checked = true;
    } else {
      this.toggleAllCheckboxTarget.checked = false;
    }
  }

  resolveColumnVisibility() {
    const hiddenColumns = JSON.parse(this.storage);

    this.columnTargets.forEach((column) => {
      if (hiddenColumns.includes(column.dataset.column)) {
        column.classList.add("hidden");
      } else {
        column.classList.remove("hidden");
      }
    });

    this.resolveToggleAllCheckboxStatus()
  }

  mutate(entries) {
    for (const mutation of entries) {
      if (mutation.addedNodes.length > 0) {
        this.resolveColumnVisibility();
      }
    }
  }


  get storage() {
    return localStorage.getItem(this.keyValue);
  }
}
