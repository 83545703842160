import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets=["drop", "input"]

  connect() {
    const dropElement = this.dropTarget;

    dropElement.addEventListener("dragenter", (event) => {
      event.preventDefault();
    });

    dropElement.addEventListener("dragover", (event) => {
      event.preventDefault();
    });

    dropElement.addEventListener("drop", (event) => {
      if(event.dataTransfer.files.length == 1) {
        event.preventDefault();
        this.inputTarget.files = event.dataTransfer.files;
        var event = new Event('change');
        this.inputTarget.dispatchEvent(event);
      }
    });
  }
}
