import { Controller } from "@hotwired/stimulus";
import { enter, leave, toggle } from "el-transition";

// Connects to data-controller="wizard"
export default class extends Controller {
  static targets = ["step"];

  connect() {
    this.step = parseInt(this.element.dataset.wizardStepIndex) || 0;
    
    if(this.step > 0) {
      this.stepTargets[0].classList.add("hidden")
      this.stepTargets[this.step].classList.remove("hidden")
    }
  }

  next() {
    this.stepTargets[this.step].classList.add("hidden");
    this.step++;
    enter(this.stepTargets[this.step], "wizard-fade");
  }

  back() {
    this.stepTargets[this.step].classList.add("hidden");
    this.step--;
    enter(this.stepTargets[this.step], "wizard-fade");
  }
}
