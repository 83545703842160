import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sidebar-menu"
export default class extends Controller {
  static targets = ["content", "chevron"]
  static values = {
    active: Boolean
  }

  connect() {
    if (this.activeValue) this.show()
  }

  toggle() {
    this.activeValue = !this.activeValue

    if (this.activeValue) {
      this.show()
    } else {
      this.hide()
    }
  }

  show() {
    this.contentTarget.classList.remove("hidden")
    this.chevronTarget.classList.remove("text-gray-400")
    this.chevronTarget.classList.add("rotate-90", "text-gray-600")
  }

  hide() {
    this.contentTarget.classList.add("hidden")
    this.chevronTarget.classList.add("text-gray-400")
    this.chevronTarget.classList.remove("rotate-90", "text-gray-600")
  }

}
