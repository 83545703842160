import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
    static targets = ["container"]
    static values = { url: String }

    connect() {
        console.log("cards--cardholder controller connected")
    }

    async show(ev) {
        const cardholderId = ev.target.value;
        const response = await get(this.urlValue, { query: { cardholder_id: cardholderId } })
        if (response.ok) {
            const html = await response.html
            this.containerTarget.innerHTML = html
        }
    }

    async form(ev) {
        const response = await get(this.urlValue, { query: { form: true } })
        if (response.ok) {
            const html = await response.html
            this.element.innerHTML = html
        }
    }

    async select() {
        const response = await get(this.urlValue)
        if (response.ok) {
            const html = await response.html
            this.element.innerHTML = html
        }
    }
}