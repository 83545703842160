import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

// Connects to data-controller="overlay"
export default class extends Controller {
  static targets = [
    "body",
    "background"
  ]

  connect() {
    this.open()
  }

  open() {
    enter(this.bodyTarget)
    enter(this.backgroundTarget)
    document.querySelector("body").classList.add("overflow-y-hidden", "pr-4")
  }

  hide() {
    leave(this.bodyTarget)
    leave(this.backgroundTarget).then(() => {
      this.element.remove()
      if (this.element.parentElement) {
        this.element.parentElement.removeAttribute("src")
      }
    })
    document.querySelector("body").classList.remove("overflow-y-hidden", "pr-4")
    this.dispatch("close")
  }

  closeBackground(e) {
    if (e && this.bodyTarget.contains(e.target)) { return }

    this.hide()
  }

  submitEnd(e) {
    if (e.detail.success) {
      this.hide()
    }
  }

  closeWithKeyboard(e) {
    if (e.code == "Escape") {
      this.hide()
    }
  }
}
