import { Controller } from "@hotwired/stimulus"
import TomSelect      from "tom-select"

// Connects to data-controller="ts--colors"
export default class extends Controller {
  static values = {
    options: Array,
    placeholder: { type: String, default: "Select an Account" }
  }

  static targets = ["input"]

  connect() {
    this.initTomSelect()
  }

  disconnect() {
    if (this.select) {
      this.select.destroy()
    }
  }

  initTomSelect() {
    this.select = new TomSelect(this.element, {
      options: this.optionsValue,
      // items: this.optionsValue[0].id,
      valueField: 'id',
      labelField: 'name',
      searchField: 'name',
      maxItems: 1,
      maxOptions: 300,
      selectOnTab: true,
      closeAfterSelect: true,
      create: false,
      openOnFocus: true,
      highlight: true,
      // controlInput: null,
      render: {
        option: this.render_option,
        item: this.render_option,
      },
      plugins: ['dropdown_input']
    })
  }

  render_option(data, escape) {
    const subtitle = (data.bank) ? escape(data.bank) : ""
    const maskElement = data.mask ? `<div class="text-right font-mono text-gray-400 text-xs pb-1">${escape(data.mask)}</div>` : ""

    return `
      <div class="!flex justify-between w-full text-sm p-2">
        <div class="flex flex-col">
          <div class="font-semibold">${escape(data.name)}</div>
          <div class="text-gray-500">${subtitle}</div>
        </div>
        <div class="flex flex-col">
          <div class="text-right font-mono text-gray-400 text-xs pb-1">${escape(data.number)}</div>
          ${maskElement}
        </div>
      </div>
    `
  }
}
