import { Controller } from "@hotwired/stimulus"
import { createPopper } from '@popperjs/core';

export default class extends Controller {
  static targets = ["content"]

  connect() {
    this.popper = createPopper(this.element, this.contentTarget, {
      modifiers: [
        {
          name: 'offset',
          options: { offset: [8, 8] }
        },
      ]
    });

    const showEvents = ['mouseenter', 'focus'];
    const hideEvents = ['mouseleave', 'blur'];

    showEvents.forEach((event) => {
      this.element.addEventListener(event, ev => { this.show() })
    })

    hideEvents.forEach((event) => {
      this.element.addEventListener(event, ev => { this.hide() })
    })
  }

  disconnect() {
  }

  show() {
    this.contentTarget.classList.remove('hidden')
    this.popper.update()
  }

  hide() {
    this.contentTarget.classList.add('hidden')
  }
}
