import { Controller } from "@hotwired/stimulus"
import IMask from 'imask';

// Connects to data-controller="imask-date"
export default class extends Controller {
  connect() {
    var dateMask = IMask(this.element, {
      mask: Date,
      pattern: 'm/`d/`Y',
    
      // define date -> str convertion
      format: function (date) {
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();

        if (day < 10) day = "0" + day;
        if (month < 10) month = "0" + month;

        return [month, day, year].join('/');
      },
      
      // define str -> date convertion
      parse: function (str) {
        var yearMonthDay = str.split('/');
        // months count from 0 to 11
        return new Date(yearMonthDay[2], yearMonthDay[0] - 1, yearMonthDay[1]);
      },
    });
  }
}
