import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

// Connects to data-controller="dropdown"
export default class extends Controller {
  static targets = ["content", "focus"]

  connect() {
    if (this.hasButtonTarget) {
      this.buttonTarget.addEventListener("keydown", this.toggleWithKeyboard)
      this.buttonTarget.setAttribute("aria-haspopup", "true")
    }
  }

  toggle() {
    this.open
      ? this.hide()
      : this.show()
  }

  show() {
    enter(this.contentTarget)
    this.open = true;
    if (this.hasFocusTarget) { this.focusTarget.focus() }
  }

  hide() {
    leave(this.contentTarget)
    this.open = false;
  }

  submitEnd(e) {
    if (e.detail.success) {
      this.hide()
    }
  }

  closeBackground(e) {
    if (e && this.element.contains(e.target)) {
      return
    }
    this.hide()
  }

  toggleWithKeyboard = (e) => {
    switch (e.keyCode) {
      case 13: // enter
      case 32: // space
        e.preventDefault()
        this.toggle()
    }
  }

}
