import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="charge--payment"
export default class extends Controller {
  static targets = ["amount", "reviewAmount", "accName", "accNumber"]

  checkAccount(ev) {
    const accName = ev.currentTarget.dataset.name
    const accNumber = ev.currentTarget.dataset.number

    this.accNameTarget.innerHTML = accName
    this.accNumberTarget.innerHTML = accNumber
  }

  checkAmount(ev) {
    if (ev.currentTarget.value == "other") {
      this.amountTarget.classList.remove("hidden")
    } else {
      this.amountTarget.classList.add("hidden")
    }
  }

  changeAmount(ev) {
    const amount = ev.target.value
    this.reviewAmountTarget.innerHTML = `$${amount}`
  }

}
