import { Controller } from "@hotwired/stimulus"

import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'
import ProgressBar from '@uppy/progress-bar'
import DropTarget from '@uppy/drop-target'

// Connects to data-controller="bills--pdf-upload"
export default class extends Controller {
  static targets =  ["button", "loading"]
  static values = {
    url: String
  }

  connect() {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content

    const uppy = new Uppy({
      debug: true,
      autoProceed: true,
      restrictions: {
        maxFileSize: (10*1024*1024),
        allowedFileTypes: ['application/pdf']
      }
    })

    uppy
  //     .use(ProgressBar, {
  //       target: '#progress-bar',
  //       hideAfterFinish: true
  //     })
      .use(XHRUpload, {
        endpoint: this.urlValue,
        headers: { "X-CSRF-Token": csrfToken }
      })
     .use(DropTarget, {
        target: this.element,
      })

      uppy
      .on('upload', (data) => {
        this.loadingTarget.classList.remove('invisible')
      })
      .on('upload-success', (file, response) => {
        uppy.cancelAll()
        this.element.outerHTML = response.body.html
        this.loadingTarget.classList.add('invisible')
      })
      .on('upload-error', (file, error, response) => {
        console.error(error)
      })
      .on('restriction-failed', (file, error) => {
        alert(error)
      })

    if(this.hasButtonTarget) {
      this.buttonTarget.addEventListener('change', (event) => {
        let file = event.target.files[0]

        try {
          uppy.addFile({
            source: 'file input',
            name: file.name,
            type: file.type,
            data: file,
          })
        } catch (err) {
          if (err.isRestriction) {
            console.log('Restriction error:', err)
          } else {
            console.error(err)
          }
        }

      })
    }

  }
}
