import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="expand"
export default class extends Controller {
  static targets = [
    "container",
    "body",
    "hideExpand",
    "showExpand"
  ]
  static values = { width: String }

  connect() {
    this.isExpanded = false

    if (this.hasHideExpandTarget) {
      this.hide(this.hideExpandTarget)
    }
  }

  expand(ev) {
    if (ev.params.url) {
      this.showExpanded(ev.params.url)
    } else {
      this.hideExpanded()
    }
  }

  toggleIsExpanded(ev) {
    if (this.isExpanded) {
      this.hideExpanded()
    } else {
      this.showExpanded(ev.params.url)
    }
    this.isExpanded = !this.isExpanded
  }

  async showExpanded(url) {
    const response = await get(url)
    if (response.ok) {
      const body = await response.html
      this.containerTarget.innerHTML = body;

      this.toggle(this.bodyTarget, "max-w-full", this.widthValue)
      this.show(this.containerTarget)
      if (this.hasHideExpandTarget) this.show(this.hideExpandTarget)
      if (this.hasShowExpandTarget) this.hide(this.showExpandTarget)

    }
  }

  hideExpanded() {
    this.toggle(this.bodyTarget, this.widthValue, "max-w-full")
    this.hide(this.containerTarget)
    if (this.hasHideExpandTarget) this.hide(this.hideExpandTarget)
    if (this.hasShowExpandTarget) this.show(this.showExpandTarget)
  }

  show(el) { el.classList.remove("hidden") }
  hide(el) { el.classList.add("hidden") }

  toggle(el, add, remove) {
    el.classList.add(add)
    el.classList.remove(remove)
  }
}
