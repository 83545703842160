import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="credit-account--request"
export default class extends Controller {
  static targets = ["spend", "spendNext", "check", "submit", "name", "nameNext"]

  connect() {
    this.nameTarget.addEventListener("keyup", () => this.enableNameNext())
    this.checkTarget.addEventListener("change", () => this.enableSubmit())
    this.enableNameNext()
    this.enableSubmit()
  }

  enableNameNext() {
    this.nameNextTarget.disabled = this.nameTarget.value.length <= 0
  }

  enableSubmit() {
    this.submitTarget.disabled = !this.checkTarget.checked
  }

  disconnect() {
    this.checkTarget.removeEventListener("change", () => this.enableSubmit())
  }
}
