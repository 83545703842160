import { Controller } from "@hotwired/stimulus"
import { post }       from '@rails/request.js'

// Connects to data-controller="transactions--memos"
export default class extends Controller {
  static targets =  ["memos"]
  static values = {
    url: String
  }

  connect() {
  }

  async submit(event) {
    if (event.keyCode == 13) {
      event.preventDefault()
      if (event.target.value.trim() != "") {
        await this.save(event.target.value)
        event.target.value = ""
      }
    }
  }

  async save(memo) {
    const response = await post(this.urlValue, {
      body: { memo: memo },
      contentType: "application/json"
    })

    if (response.ok) {
      let html = await response.html
      this.memosTarget.innerHTML = html
    } else {
      console.error(response.error)
    }

  }
}
