import { Controller } from "@hotwired/stimulus";
import { post } from "../helpers/api";
import { get } from '@rails/request.js';

export default class extends Controller {
  static values = {
    callbackUrl: String,
    linkTokenKey: String,
    callbackType: { type: String, default: "redirect" } // redirect, modal, turbo-stream
  }

  connect() {
    console.log("Plaid controller connected");
  }

  connectWithPlaid() {
    this.disableButton();
    this.createLinkToken();
  }

  async createLinkToken() {
    try {
      const response = await post("/plaid/link_token");
      const data = await response.json();

      if (data.link_token) {
        localStorage.setItem(`LINK_TOKEN_KEY_${this.linkTokenKeyValue}`, data.link_token);

        Plaid.create({
          token: data.link_token,
          onLoad: () => {
            console.log("Plaid Link loaded");
          },
          onSuccess: async (public_token, metadata) => {
            try {
              const accessTokenRes = await post("/plaid/access_token", {
                public_token,
                institution: metadata.institution,
                accounts: metadata.accounts
              });

              await this.handleAccessTokenResponse(accessTokenRes);
            } catch (error) {
              console.error("Error in access token request:", error);
              alert("An error occurred while processing Plaid!");
            } finally {
              this.enableButton();
            }
          },
          onExit: (err, _metadata) => {
            if (err !== null) {
              console.error("Plaid API error:", err);
            }
            this.enableButton();
          },
          onEvent: (_eventName, _metadata) => {
            // Optionally capture Link flow events
          },
        }).open();
      } else {
        console.error("Failed to retrieve link token");
        alert("Failed to retrieve link token");
        this.enableButton();
      }
    } catch (e) {
      console.error("Error creating link token:", e);
      this.enableButton();
    }
  }

  async handleAccessTokenResponse(response) {
    console.log('Access Token Response:', response);

    if (response.ok) {
      const data = await response.json();
      console.log('Access Token Data:', data);

      if (data.success) {
        alert("Bank connected successfully!");
        this.handleCallback();
      } else {
        console.error('Access Token Error:', data.message);
        alert('Error: ' + data.message);
      }
    } else {
      console.error('Access Token HTTP Error:', response.status, response.statusText);
      alert('HTTP Error: ' + response.statusText);
    }
  }

  async handleCallback() {
    switch (this.callbackTypeValue) {
      case 'redirect':
        Turbo.visit(this.callbackUrlValue);
        break;

      case 'modal':
        const frame = document.querySelector("turbo-frame#modal");
        frame.src = this.callbackUrlValue;
        frame.reload();
        break;

      case 'turbo-stream':
        const response = await get(this.callbackUrlValue, {
          responseKind: 'turbo-stream',
        });

        if (!response.ok) {
          console.error(response);
        }
        break;
    }
  }

  disableButton() {
    this.originalText = this.element.innerHTML;
    this.element.disabled = true;
    this.element.innerHTML = "Connecting...";
  }

  enableButton(text) {
    this.element.disabled = false;
    this.element.innerHTML = text || this.originalText;
  }

  confirmManualVerification() {
    this.disableButton();
    this.createManualVerifyLinkToken();
  }

  async refreshAccount(event) {
    const button = event.target.closest("button");
    const accountId = button.dataset.accountId;
    console.log("Refresh button clicked");
    console.log("Account ID:", accountId);
    try {
      const response = await post(`/plaid/${accountId}/refresh`);
      const data = await response.json();  // Ensure we parse the JSON
      this.handleRefreshResponse(data, accountId);
    } catch (error) {
      this.handleRefreshError(error);
    }
  }

  handleRefreshResponse(data, accountId) {
    if (data.success) {
      alert("Account refreshed successfully");
      location.reload();
    } else {
      if (data.message.includes("ITEM_LOGIN_REQUIRED")) {
        this.reauthenticateAccount(accountId);
      } else {
        alert(data.message || "Failed to refresh account");
      }
    }
  }

  handleRefreshError(error) {
    console.error("Error refreshing account:", error);
    alert("An error occurred while refreshing the account");
  }

  reauthenticateAccount(accountId) {
    this.requestLinkToken(accountId)
      .then(data => this.handleLinkTokenResponse(data, accountId))
      .catch(error => this.handleLinkTokenError(error));
  }

  async requestLinkToken(accountId) {
    const response = await post(`/plaid/${accountId}/link_token_update`);
    return response.json();
  }

  handleLinkTokenResponse(data, accountId) {
    if (data.link_token) {
      this.openPlaidLink(data.link_token, accountId);
    }
  }

  handleLinkTokenError(error) {
    console.error("Error requesting link token:", error);
    alert("An error occurred while requesting a new link token");
  }

  openPlaidLink(link_token, accountId) {
    Plaid.create({
      token: link_token,
      onSuccess: (public_token, metadata) => this.handlePlaidSuccess(public_token, metadata, accountId),
      onExit: (err, metadata) => this.handlePlaidExit(err),
    }).open();
  }

  handlePlaidSuccess(public_token, metadata, accountId) {
    post(`/plaid/${accountId}/access_token`, { public_token, metadata })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          alert("Account re-authenticated successfully");
          location.reload();
        } else {
          alert(data.message || "Failed to re-authenticate account");
        }
      });
  }

  handlePlaidExit(err) {
    if (err) {
      console.error("Plaid Link exited with error:", err);
      alert("Re-authentication failed. Please try again.");
    }
  }

  async createManualVerifyLinkToken() {
    try {
      const res = await post(`/plaid/${this.element.dataset.plaidAccountId}/manual_verification_link_token`);
      const {link_token} = await res.json();
      Plaid.create({
        token: link_token,
        onLoad: () => {},
        onSuccess: async (public_token, metadata) => {
          await post(
            `/plaid/${this.element.dataset.plaidAccountId}/manual_verification_complete`,
            { verification_status: metadata.accounts[0].verification_status,
              public_token: public_token,
              accounts: metadata.accounts }
          );
          this.enableButton("Confirm Deposits");
          window.location.replace("/plaid")
        },
        onExit: (err, _metadata) => {
          this.enableButton("Confirm Deposits");
        },
        onEvent: (_eventName, _metadata) => {},
      }).open();
    } catch (e) {
      this.enableButton("Confirm Deposits");
    }
  }
}
