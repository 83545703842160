import { Controller } from "@hotwired/stimulus";

import { post } from "../helpers/api";
import { get }  from '@rails/request.js'

export default class extends Controller {
  static values = {
    linkTokenKey: String
  }

  connect() {
    this.createLinkToken()
  }

  async createLinkToken() {
    try {
      const link_token = localStorage.getItem(`LINK_TOKEN_KEY_${this.linkTokenKeyValue}`)

      Plaid.create({
        token: link_token,
        receivedRedirectUri: window.location.href,
        onLoad: () => {},
        onSuccess: async (public_token, metadata) => {
          const accessTokenRes = await post("/plaid/access_token", {
            public_token,
            institution: metadata.institution,
            accounts: metadata.accounts
          });

          if (accessTokenRes) {
            if (!accessTokenRes['success']) {
              console.error(accessTokenRes)
              alert(accessTokenRes['message'] || "Something went wrong while processing Plaid!")
            }
          }
          window.location.replace("/plaid")
        },
        onExit: (err, _metadata) => {
          // The user exited the Link flow.
          if (err !== null) {
            // The user encountered a Plaid API error prior to exiting.
          }
        },
        onEvent: (_eventName, _metadata) => {},
      }).open();
    } catch (e) {
      console.error("Oauth Error", e);
    }
  }
}
