import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["street", "street2", "city", "state", "zip"]

  connect() {
    if (typeof (google) != "undefined") {
      this.initializeMap()
    } else {
      console.error("Google Maps API not loaded")
    }
  }

  initializeMap() {
    this.autocomplete()
  }

  autocomplete() {
    if (this._autocomplete == undefined) {
      this._autocomplete = new google.maps.places.Autocomplete(this.streetTarget)
      this._autocomplete.setFields(['address_components', 'geometry', 'icon', 'name'])
      this._autocomplete.addListener('place_changed', this.locationChanged.bind(this))
    }
    return this._autocomplete
  }

  locationChanged() {
    let place = this.autocomplete().getPlace()

    this.streetTarget.value = this.getStreetNumber(place) || ""
    this.cityTarget.value = this.getAddressKeyValue(place, "locality").long_name || this.getAddressKeyValue(place, "sublocality").long_name
    this.stateTarget.value = this.getAddressKeyValue(place, "administrative_area_level_1").short_name
    this.stateTarget.dispatchEvent(new Event('change'))
    this.zipTarget.value = this.getAddressKeyValue(place, "postal_code").long_name
  }

  getAddressKeyValue(place, key) {
    let obj = place.address_components.find(x => x.types.includes(key))
    return {
      short_name: (obj && obj.short_name) ? obj.short_name : "",
      long_name: (obj && obj.long_name) ? obj.long_name : "",
    }
  }

  getStreetNumber(place) {
    let route = this.getAddressKeyValue(place, "route").long_name
    let street = this.getAddressKeyValue(place, "street_number").long_name

    return [street, route].filter(ele => ele).join(" ")
  }
}
