export const post = async (url, params = {}) => {
  try {
    const response = await fetch(url, {
      mode: "cors",
      method: "POST",
      cache: "no-cache",
      credentials: "same-origin",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response; // Return the Response object
  } catch (error) {
    console.error("Error in POST request:", error);
    throw error;
  }
};
