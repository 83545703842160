import { Controller } from "@hotwired/stimulus" 

export default class extends Controller {
  static targets = [ 
    "unitPrice", "transactionSplitAmount", "countAddedItems"
  ]

  connect() {
  }

  handleCountAddedItemsChange(event) {
    let countAddedItems = parseFloat(this.countAddedItemsTarget.value)
    let unitPrice = ""

    if (countAddedItems > 0) {
      unitPrice = this.roundToX(parseFloat(this.transactionSplitAmountTarget.value) / countAddedItems)
    }

    this.unitPriceTarget.value = unitPrice
  }

  roundToX(num) {    
    let x = 2
    return +(Math.round(num + "e+"+x)  + "e-"+x)
  }
}
