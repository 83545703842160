import { Controller } from "@hotwired/stimulus"
import { get }       from '@rails/request.js'

export default class extends Controller {
  static values = {
    activeUrl: String,
    timeoutUrl: String,
    interval: Number /* time interval between requests (seconds) */
  }

  initialize() {
    this.callback = this.checkSessionActive.bind(this);
  }

  connect() {
    this.timeout = setTimeout(this.callback, this.intervalValue * 1000)
  }

  disconnect() {
    if(this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  async checkSessionActive() {
    const response = await get(this.activeUrlValue, {responseKind: "json"})

    if (response.statusCode == 200) {
      this.timeout = setTimeout(this.callback, this.intervalValue * 1000)

    } else if(response.statusCode == 401) {
      window.location.href = this.timeoutUrlValue

    } else {
      console.error(response.error)
    }
  }
}
