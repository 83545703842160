import { Controller } from "@hotwired/stimulus"
import { put } from '@rails/request.js'

export default class extends Controller {
  static values = {
    url: String,
    integration: String
  }

  async save(ev) {

    const body = { [ev.params.type]: { [this.integrationValue]: ev.target.value } }


    this.put(body)
  }

  async put(body) {
    const response = await put(this.urlValue, { body: body })

    if (response.ok) {
      // nope
    } else {
      alert("Error saving category")
      const body = await response.text
      console.error(body)
    }
  }
}
