import { Controller } from "@hotwired/stimulus"

// Example usage:
// <div data-controller="toggler" data-toggler-show-value="ach">
//   <%= form.select(
//         "payment_type",
//         options_for_select(["ach", "manual"]),
//         {},
//         data: {toggler_target: "field"} %>
//
//   <div data-toggler-target="toggle">
//     <!-- this content will be shown or hidden depending on the <select> value -->
//   </div>
// </div>

export default class extends Controller {
  static targets = [ "field", "toggle" ]
  static values = {
    boolean: Boolean,
    default: String,
    show: String,
    hide: String
  }


  connect() {
    if (this.hasFieldTarget) {

      this.fieldTarget.addEventListener('change', (e) => {
        this.onChangeValue(this.fieldTarget.value)
      })

      this.onChangeValue(this.fieldTarget.value)

    } else if (this.hasDefaultValue) {
      this.onChangeValue(this.defaultValue)
    }
  }

  onChangeValue(value) {
    this.value = value
    this.toggleTargets.forEach(t => this.toggle(t))
  }

  change(event) {
    this.onChangeValue(event.target.value)
  }

  toggle(t) {
    let showToggle =
      (this.booleanValue && this.fieldTarget.checked) ||  // field is a checkBox
      this.includeValue(this.showValue) ||               // general show-value geral, defined in the controller
      this.includeValue(t.dataset.showValue) ||          // show-value specific for this target
      this.notIncludeValue(this.hideValue) ||            // general hide-value geral, defined in the controller
      this.notIncludeValue(t.dataset.hideValue)          // hide-value specific for this target

    if (this.element.dataset.hasOwnProperty("togglerShowValue")) { // sometimes hideValue = "" (empty string!)
      showToggle = this.showValue.split('|').includes(this.value)
    }

    if (showToggle) {
      this.show(t)
    } else {
      this.hide(t)
    }
  }

  show(el) { el.style.display = 'block'; }
  hide(el) { el.style.display = 'none'; }

  includeValue(where) {
    return where && where.split('|').includes(this.value)
  }

  notIncludeValue(where) {
    return where && !where.split('|').includes(this.value)
  }

}
