import { Controller } from "@hotwired/stimulus"
import { post }       from '@rails/request.js'

// Connects to data-controller="accounting--transactions"
export default class extends Controller {
  static values = {
    url: String,
    tid: String
  }

  async sync(ev) {
    const response = await post(this.urlValue, {
      body: { transaction_ids: [this.tidValue] },
      contentType: "application/json"
    })

    if (response.ok) {
      // nope
    } else {
      alert("Error syncing transaction")
      const body = await response.text
      console.error(body)
    }

  }
}
