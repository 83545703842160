import { Controller } from "@hotwired/stimulus"
import { post }       from '@rails/request.js'

// Connects to data-controller="splits--single"
export default class extends Controller {
  static values = { transactionAmount: Number }
  static targets = ["form", "amount", "error", "splitContainer", "inventoryContainer", "workOrderContainer"]

  connect() {
    this.restyle()

    this.element.addEventListener('turbo:submit-end', this.handleSubmitEnd.bind(this));

    this.somethingWasChanged = false
  }

  handleSubmitEnd() {
    setTimeout(() => { // this is needed because it appears that html is replaced after this method (turbo:submit-end)
      this.restyle()
    }, 50)
    
  }

  restyle() {
    if(this.visibleSplitContainerTargets().length > 1) {
      this.formTarget.classList.add('has-multiple-splits')
    } else {
      this.formTarget.classList.remove('has-multiple-splits')
    }

    this.recalculateSums();
  }

  visibleSplitContainerTargets() {
    return this.splitContainerTargets.filter(obj => obj.style.display != "none")
  }

  countVisibleSplits() {
    return visibleSplitContainerTargets().length
  }

  visibleAmountTargets() {
    let array = []

    this.visibleSplitContainerTargets().forEach((container) => {
      array = array.concat(container.querySelector(".amount-input"))
    })

    return array
  }

  recalculateSums() {
    let sum = this.roundToX(this.visibleAmountTargets().reduce((accumulator, target) => {
      return accumulator + parseFloat(target.value || 0)
    }, 0))

    if(sum != this.transactionAmountValue) {
      this.errorTarget.innerHTML = `Sum of splits $${sum} does not equal $${this.transactionAmountValue}. DIFF = ${this.roundToX(sum - this.transactionAmountValue)}`
      this.errorTarget.classList.remove("hidden")
    } else {
      this.errorTarget.classList.add("hidden")
    }
  }

  amountInputHandler(e) {
    this.recalculateSums()

    let targets = this.visibleAmountTargets()

    const index = targets.indexOf(e.currentTarget);

    if (index == 1 && targets.length == 2) {
      if(e.currentTarget.value) {
        targets[0].value = Math.max(0, this.roundToX(this.transactionAmountValue - parseFloat(e.currentTarget.value)))
      } else {
        targets[0].value = this.roundToX(this.transactionAmountValue)
      }
      
      this.recalculateSums()
    }
  }

  inputHandler(e) {
    this.somethingWasChanged = true
  }

  dropdownBlurHandler(e) {
    if(this.somethingWasChanged) { // if input is focused prior opening a dropdown, we don't immediatelly send data to the server
      this.submitForm()
      this.somethingWasChanged = false
    }
  }

  submitForm() {
    const focusedElement = document.activeElement; 
    if(focusedElement) { // if dropdown is open (focused) it will be re-opened when html will be replaced
      focusedElement.blur()
    }


    this.somethingWasChanged = false
    this.formTarget.requestSubmit()
  }

  blurHandler(e) { // for inputs only (not for dropdowns)
    if(!e.target.value) { // submitting empty value will remove the item on the backend. It is better to not submit form at all in this case
      return false
    }

    if (this.lastBlurAt && this.lastBlurAt > Date.now() - 500) {
      // Chrome bug/issue: 
      // First create 2 splits. 
      //   - click into first amount input
      //   - then click into another amount input => this lead to Chrome tab crash 
      // WHY: because second input receives onBlur as well when HTML is replaced! 

      // TMP workaround is to not submit form if it was already submitted half a second ago
      return
    }
    
    this.lastBlurAt = Date.now()
    
    setTimeout(() => this.submitFormUnlessAnotherInputIsFocused(), 30)
  }

  submitFormUnlessAnotherInputIsFocused() {
    const focusedElement = document.activeElement;

    if((focusedElement.tagName == "INPUT" || focusedElement.tagName == "SELECT") && focusedElement.id.indexOf("_splits_") > 0) {
      // another input was clicked. let's submit when this input will be blurred 
      console.log("another input is focused. let's submit when this input will be blurred ")
    } else {
      this.submitForm()
    }
  }

  roundToX(num) {    
    let x = 2
    return +(Math.round(num + "e+"+x)  + "e-"+x)
  }

  propertyChanged(e) {
    let propertyId = e.currentTarget.value

    let htmlDropdownId = e.currentTarget.getAttribute("id").replace('_property_id', '_work_order_id') // debit_transaction_splits_attributes_1705941251070_property_id

    let htmlDropdownName = e.currentTarget.getAttribute("name").replace("property_id", "work_order_id")

    // get(`/work_orders/dropdown.turbostream?property_id=${propertyId}&html_dropdown_id=${htmlDropdownId}&html_dropdown_name=${htmlDropdownName}`, { 
    //   responseKind: "turbo-stream" 
    // })

    this.submitForm()
  }

  workOrderChanged(e) {
    this.submitForm()
  }

  categoryChanged(e) {
    this.submitForm()
  }

  deleteHandler(e) {
    setTimeout(() => { // nested form is apparently using event.stopPropagation(). This is "not so nice workaround"
      let visibleTargets = this.visibleAmountTargets()

      if(visibleTargets.length == 1) {
        visibleTargets[0].value = this.transactionAmountValue
      }

      this.submitForm()
      this.restyle()
    }, 50)  
  }

  addHandler(e) {
    this.restyle()
  }

  async save(params) {
    const payload = {
      split: params
    }
    
    const response = await post(this.urlValue + ".turbostream", { body: payload, responseKind: "turbo-stream" }) //contentType: "application/json"

    if (response.ok) {
      // ok
    } else {
      console.error(response.error)
    }
  }

}
