import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form"]

  submit() {
    this.stripFilters()
    this.form.requestSubmit()
  }

  stripFilters() {
    this.element.querySelectorAll('input').forEach((input) => {
      if (input.name.startsWith("search")) {
        input.remove()
        input.removeAttribute('name')
      }
    })
  }

  get form() {
    if (this.hasFormTarget) {
      return this.formTarget
    } else {
      return this.element
    }
  }

}
